/* stylelint-disable value-list-comma-newline-after */

/* ----- GT Super ----- */

/* Light */
@font-face {
  font-family: 'RBold';
  src:
    url('/assets/fonts/themes/dex/replica/bold/ReplicaLLWeb-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/themes/dex/replica/bold/ReplicaLLWeb-Bold.woff') format('woff'); /* Pretty Modern Browsers */

  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
