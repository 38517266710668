html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

a,
a:visited,
a:focus,
a:target {
  color: inherit;
}

ol,
ul {
  text-indent: 0 !important;
}
