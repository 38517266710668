@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.org--default-slider,
.slider--glide {
  height: 100%;
  width: 100%;
  grid-column-end: span 12;

  .glide__track {
    height: 100%;
    width: 100%;
  }

  .glide__arrows {
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    background: red;

    .glide__arrow:disabled {
      display: none;
    }
  }
}
