.itm--teaser-text {
  // grid-column-end: span 6;
  // grid-column-start: 1;
  text-align: center;
  padding-bottom: 150px;
  padding-left: 5%;
  padding-right: 5%;

  &__content {
    h1 {
      @include fluid-type(700px, 1200px, 36px, 76px);
    }

    h2 {
      @include fluid-type(700px, 1200px, 12px, 36px);

      margin-bottom: 24px;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      h2 {
        font-size: 12px;
        margin-bottom: 24px;
      }

      h1 {
        font-size: 26px;
        line-height: 1.1;
        margin-bottom: 24px;
      }
    }
  }

  &.text-align--left {
    text-align: left;
  }
}
