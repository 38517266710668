.mod--further-action {
  padding: 0 map-get($default-side-padding, desktop) 80px;
  text-align: left;

  &__content {
    max-width: 50%;

    @include breakpoint-max(map-get($breakpoints, small)) {
      max-width: 100%;
    }

    p {
      font-size: 18px;
      margin-bottom: 60px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }

    a p {
      margin-bottom: 0;
      font-size: 20px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        font-size: 12px;
      }
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 0 map-get($default-side-padding, small) 50px;
  }
}
