@mixin breakpoint-max($val) {
  @media only screen and (max-width: $val) {
    @content;
  }
}

@mixin height($step) {
  &.window-height--#{ $step } {
    // height: #{calc(100vh / 12 * #{$step})};
    min-height: #{calc(100vh / 12 * #{$step})};

    // @include breakpoint-max(map-get($breakpoints, small)) {
    //   min-height: #{calc((100vh / 12 * #{$step}) * 0.8)};
    // }

    .itm--teaser-image__media {
      min-height: #{calc(100vh / 12 * #{$step})};

      // @include breakpoint-max(map-get($breakpoints, small)) {
      //   min-height: #{calc((100vh / 12 * #{$step}) * 0.8)};
      // }
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@each $name, $background in $colors {
  .background-color--#{$name} {
    background: #{$background};
  }
};

@each $name, $background in $dark-colors {
  .background-color--#{$name} {
    background: #{$background};
    color: map-get($colors, white);

    .btn {
      @extend %button-bright;
    }

    svg {
      fill: map-get($colors, white);
    }
  }
};

@mixin flex-basis-fix($val) {
  flex-basis: $val;
  max-width: $val; /* ie fix */
}
