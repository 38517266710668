button,
button:focus,
button:target {
  appearance: none;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  padding: 0 #{$default-spacing * 4};
  border: map-get($styling, default-border) map-get($colors, black);
  display: inline-block;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-out;

  @include breakpoint-max(map-get($breakpoints, small)) {
    min-width: 120px;
  }

  p {
    font-size: 24px;
    line-height: 40px;
    margin: 0;
    z-index: 2;

    @include breakpoint-max(map-get($breakpoints, small)) {
      font-size: 12px;
      padding: 6px 20px;
      line-height: 1.33;
    }
  }

  &:target,
  &:focus,
  &:hover {
    background: map-get($colors, black);
    color: map-get($colors, white);
  }
}

%button-bright {
  @extend .btn;

  color: inherit;
  border-color: map-get($colors, white);
  overflow: hidden;
  transition: 0.3s all ease-in-out;


  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: map-get($colors, white);
    transition: 0.3s all ease-in-out;
    z-index: 0;
  }

  &:hover {
    color: map-get($colors, black);

    &::after {
      width: 100%;
    }
  }
}
