.mod--stage {
  /* Height Variables */
  @for $i from 1 through 12 {
    @include height($i);
  }

  &__media {
    height: 100vh;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &__headline {
      text-align: center;
      color: map-get($colors, white);
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      &__logo {
        // margin-bottom: 0;
        z-index: 3;
        position: relative;
        width: 500px;
        height: 59px;
        display: inline-block;

        @include breakpoint-max(map-get($breakpoints, small)) {
          width: 250px;
          height: 25px;
        }

        svg {
          width: 100%;
          height: 100%;
          // max-width: 250px;
          display: block;
          fill: map-get($colors, white);
        }
        // font-size: 80px;
      }

      &__cross {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      // .cross-line {
      //   width: 40px;
      //   height: 200px;
      //   left: 50%;
      //   top: 50%;
      //   position: absolute;

      //   &::before {
      //     content: '';
      //     border-right: 3px solid cyan;
      //     border-top: 3px solid transparent;
      //     border-bottom: 3px solid transparent;
      //     width: 0;
      //     top: 0;
      //     left: -3px;
      //     position: absolute;
      //     height: 194px;
      //   }

      //   &::after {
      //     content: '';
      //     border-left: 3px solid cyan;
      //     border-top: 3px solid transparent;
      //     border-bottom: 3px solid transparent;
      //     width: 0;
      //     top: -1px;
      //     right: -2px;
      //     position: absolute;
      //     height: 196px;
      //   }

      //   &--first {
      //     background: map-get($colors, yellow);
      //     transform: translate(-50%, -50%) rotate(45deg);
      //     transform-origin: center center;

      //     &::before {
      //       border-right-color: map-get($colors, yellow);
      //     }

      //     &::after {
      //       border-left-color: map-get($colors, yellow);
      //     }
      //   }

      //   &--second {
      //     background: map-get($colors, light-blue);
      //     transform: translate(-50%, -50%) rotate(-45deg);
      //     transform-origin: center center;

      //     &::before {
      //       border-right-color: map-get($colors, light-blue);
      //     }

      //     &::after {
      //       border-left-color: map-get($colors, light-blue);
      //     }
      //   }
      // }
    }

    &.inserted {
      z-index: 4;
    }

    &__description {
      bottom: 25vh;
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      color: map-get($colors, white);
    }
  }
}
