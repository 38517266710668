footer {
  padding: #{$default-spacing * 6} map-get($default-side-padding, desktop);

  .org--footer-links__anchor p {
    font-size: 16px;
    display: inline-block;

    @include breakpoint-max(map-get($breakpoints, small)) {
      font-size: 12px;
    }
  }

  .footer__bottom-links {
    display: flex;
    justify-content: space-between;

    @include breakpoint-max(map-get($breakpoints, small)) {
      display: block;
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: #{$default-spacing * 6} map-get($default-side-padding, small);
  }
}
